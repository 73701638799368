<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('用户信息.手机号')" prop="mobile">
                <a-input v-model="queryParam.mobile" :placeholder="$t('用户信息.请输入手机号')" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('用户信息.邮箱')" prop="email">
                <a-input v-model="queryParam.email" :placeholder="$t('用户信息.请输入邮箱')" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('用户信息.昵称')" prop="nickname">
                  <a-input v-model="queryParam.nickname" :placeholder="$t('用户信息.请输入昵称')" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('用户信息.性别')" prop="sex">
                  <a-select placeholder="请选择性别" v-model="queryParam.sex" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type.sys_user_sex" :key="index" :value="d.value">{{
                      d.label
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('用户信息.邀请码')" prop="invitationCode">
                  <a-input v-model="queryParam.invitationCode" :placeholder="$t('用户信息.请输入邀请码')" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('用户信息.是否推送')" prop="isNotice">
                  <a-select placeholder="请选择是否推送" v-model="queryParam.isNotice" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type.sys_notice_status" :key="index" :value="d.value">{{
                      d.label
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item :label="$t('用户信息.用户状态')" prop="status">
                  <a-select placeholder="请选择用户状态" v-model="queryParam.status" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in dict.type.sys_enable_disable" :key="index" :value="d.value">{{
                      d.label
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('通用.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" />{{ $t('通用.重置') }}</a-button
                >
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? $t('通用.收起') : $t('通用.展开') }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :sexOptions="dict.type.sys_user_sex"
        :isNoticeOptions="dict.type.sys_notice_status"
        :statusOptions="dict.type.sys_enable_disable"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="sex" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_user_sex']" :value="record.sex" />
        </span>
        <span slot="isNotice" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_notice_status']" :value="record.isNotice" />
        </span>
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_enable_disable']" :value="record.status" />
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUser, listUser, delUser } from '@/api/biz/user'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'User',
  components: {
    CreateForm,
  },
  mixins: [tableMixin],
  dicts: ['sys_user_sex', 'sys_notice_status', 'sys_enable_disable'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        email: null,
        nickname: null,
        sex: null,
        invitationCode: null,
        isNotice: null,
        status: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '性别',
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: '生日',
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '邀请码',
          dataIndex: 'invitationCode',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '是否推送',
          dataIndex: 'isNotice',
          scopedSlots: { customRender: 'isNotice' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: '用户状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
      ],
    }
  },
  filters: {},
  created() {
    this.getList()
    this.updateColumns()
  },
  computed: {},
  watch: {
    // 监听语言变化，语言可能存储在 Vuex 或 localStorage 中
    '$i18n.locale'(newLocale) {
      this.updateColumns() // 当语言变化时更新列配置
    },
  },
  methods: {
    /** 查询用户信息列表 */
    getList() {
      this.loading = true
      pageUser(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        mobile: undefined,
        email: undefined,
        nickname: undefined,
        sex: undefined,
        invitationCode: undefined,
        isNotice: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delUser(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'biz/user/export',
            {
              ...that.queryParam,
            },
            `用户信息_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
    updateColumns() {
      // 根据当前语言获取表格列配置
      const currentLocale = this.$i18n.locale
      const columnsConfig = {
        en: [
          {
          title: 'cell phone number',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'email',
          dataIndex: 'email',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'nickname',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'gender',
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'birthday',
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'Invitation code',
          dataIndex: 'invitationCode',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'Push or not',
          dataIndex: 'isNotice',
          scopedSlots: { customRender: 'isNotice' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'user state',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'CreateTime',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        ],
        'zh-CN': [
          {
            title: '手机号',
            dataIndex: 'mobile',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '邮箱',
            dataIndex: 'email',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '昵称',
            dataIndex: 'nickname',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '性别',
            dataIndex: 'sex',
            scopedSlots: { customRender: 'sex' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: '生日',
            dataIndex: 'birthday',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '邀请码',
            dataIndex: 'invitationCode',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '是否推送',
            dataIndex: 'isNotice',
            scopedSlots: { customRender: 'isNotice' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: '用户状态',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: '注册时间',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
          },
        ],
      }
      this.columns = columnsConfig[currentLocale] || columnsConfig['en']
    },
  },
}
</script>
